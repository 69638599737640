import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { colors, formatSeconds } from './helpers'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, LineClamp } from './Typography'
import { Flex, Box, Container } from './Layout'
import { Input } from './Input'
import Icon from './Icon'
import Button from './Button'
import { BigDarkSpinner } from './Spinner'
import api from '../api'

import ModalContainer from './ModalContainer'
import * as Sentry from '@sentry/react'
import { useAuth } from '../Auth'
import bluebird from 'bluebird'
import { ProgressBar, ProgressBarProgress, SpinningIcon } from './ProgressBar'
import axios from '../../node_modules/axios/index'
import ImageComponent from './Image'
import PopoverDropdown from './PopoverDropdown'

const Grid = styled(Flex)`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
`

const DragDropContainer = styled(Flex)`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`

const StyledInput = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  background-color: red;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

const Hover = styled(Flex)`
  &:hover {
    border: 3px solid #5bb3f9;
    .settings-hover {
      display: flex;
    }
  }
  border: 3px solid white;
  padding: 4px;

  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
`

export const getHeightAndWidthFromDataUrl = (dataURL) =>
  new Promise((resolve) => {
    const img = new Image()
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width
      })
    }
    img.src = dataURL
  })

const TABS = {
  UPLOAD: 'UPLOAD',
  EXISTING: 'EXISTING'
}

const TabText = styled(Text)`
  ${(props) => !props.isActive && `color: ${colors.textLight};`}
  padding-bottom: 20px;
  ${(props) => props.isActive && `border-bottom: 1px solid ${colors.green};`}
  font-weight: 600;
  margin-right: 30px;
  cursor: pointer;
`

const Tab = ({ onClick, title, isActive }) => {
  return (
    <TabText onClick={onClick} isActive={isActive} light={isActive ? undefined : true}>
      {title}
    </TabText>
  )
}

const ImageUploadModal = ({ isOpen, setIsOpen, saveFunction, multiple, accept }) => {
  const [files, setFiles] = useState()
  const { user } = useAuth()
  const [isFinished, setIsFinished] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [existingImages, setExistingImages] = useState()
  const [numberUploaded, setNumberUploaded] = useState(0)
  const [error, setError] = useState(false)

  const [activeTab, setActiveTab] = useState(TABS.UPLOAD)

  const save = (urls) => {
    saveFunction && saveFunction(urls)
    setIsOpen(false)
    setIsUploading(false)
  }

  const asyncFunc = async () => {
    if (isOpen) {
      const res = await api.get('/images/get')
      setExistingImages(res.data.payload)
    }
  }
  useEffect(() => {
    asyncFunc()
  }, [isOpen])

  useEffect(() => {
    const func = async () => {
      if (files && files.length) {
        setIsUploading(true)
        const urls = await bluebird.mapSeries(files, async (file, i) => {
          let formData = new FormData()
          const fileAsDataURL = window.URL.createObjectURL(file)
          const { height, width } = await getHeightAndWidthFromDataUrl(fileAsDataURL)

          formData.append('file', file)
          formData.append('name', file.name)
          formData.append('path', 'clubb-images')
          formData.append('height', height)
          formData.append('width', width)

          console.log(formData)
          const res = await api.post('/images/generic-upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })

          if (!res.data.success) {
            setIsUploading(false)
            setError(res.data.message)
          } else {
            setError('')
          }

          setNumberUploaded(i)
          await bluebird.delay(500)
          console.log(res.data)
          return res?.data?.payload?.url
        })
        console.log({ urls })
        setIsFinished(true)
        setTimeout(() => {
          save(urls)
        }, 500)
      }
    }
    func()
  }, [files])

  const UploadInner = (
    <>
      <Flex height="100%" flexDirection="column" justifyContent="center" alignItems="center">
        <Text light mb="8px" width="550px" wordBreak="break-all">
          {files && files[0] && files[0].name}
        </Text>
        <ProgressBar mb="8px" width="550px">
          <ProgressBarProgress
            finished={numberUploaded === files?.length - 1}
            width={numberUploaded === files?.length - 1 ? `100%` : `${(numberUploaded / (files?.length - 1)) * 100}%`}
          />
        </ProgressBar>
      </Flex>
      {/* <Flex justifyContent="flex-end" borderTop="1px solid rgba(237, 242, 247, 1)" py="12px" px="24px">
        <Button
          onClick={() => {
            setIsOpen(false)
          }}
          variant="green"
          label="Done"
        />
      </Flex> */}
    </>
  )

  return (
    <ModalContainer interiorClose p="0px" width="800px" height="500px" isOpen={isOpen} setIsOpen={setIsOpen} onClose={() => null}>
      <Flex borderBottom="1px solid rgba(237, 242, 247, 1)" px="24px" pt="24px">
        <Flex>
          <Tab onClick={() => setActiveTab(TABS.UPLOAD)} title="Upload" isActive={activeTab === TABS.UPLOAD} />
          <Tab onClick={() => setActiveTab(TABS.EXISTING)} title="Library" isActive={activeTab === TABS.EXISTING} />
        </Flex>
      </Flex>
      {activeTab === TABS.UPLOAD ? (
        isUploading ? (
          <Flex flexDirection="column" alignItems="center" justifyContent="center" height="100%">
            {UploadInner}
          </Flex>
        ) : (
          <Flex flexDirection="column" alignItems="center" justifyContent="center" height="100%" p="24px">
            <DragDropContainer>
              <svg width="208" height="115" viewBox="0 0 208 115" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="21.1807" width="84" height="83" rx="8" transform="rotate(-7.08688 0 21.1807)" fill="#FDF4E8" />
                <rect
                  x="60.7319"
                  y="7.90576"
                  width="84"
                  height="83"
                  rx="8"
                  transform="rotate(11.9131 60.7319 7.90576)"
                  fill="#5BB3F9"
                />
                <rect x="135.563" width="84" height="83" rx="8" transform="rotate(30.9131 135.563 0)" fill="#F4ACC4" />
              </svg>
              <Button mt="24px" mb="24px" variant="green" label="Upload" />
              <Text light fontSize="14px" fontWeight="500">
                or drag file here
              </Text>
              {error ? <Error mt="24px">{error}</Error> : null}

              <StyledInput
                type="file"
                id="clipara-file-picker"
                name="file-picker"
                accept={accept || 'image/jpg, image/png, image/jpeg'}
                multiple={multiple ? 'multiple' : false}
                onChange={(e) => {
                  const filePicker = document.getElementById('clipara-file-picker')
                  console.log(filePicker.files)
                  if (filePicker.files[0]) {
                    setFiles(Array.from(filePicker.files))
                  }
                }}
              />
            </DragDropContainer>
          </Flex>
        )
      ) : null}
      {activeTab === TABS.EXISTING ? (
        <Flex flexDirection="column" alignItems="center" px="16px">
          <Grid py="16px" maxHeight="500px" overflowY="scroll" noScrollBar>
            {(existingImages || []).map((image) => {
              return (
                <Hover
                  key={image.id}
                  onClick={() => {
                    save([`https://clipara.b-cdn.net${image.path}`])
                  }}
                >
                  <Flex height="170px" position="relative">
                    <Flex position="absolute" top="5px" right="5px">
                      <Flex
                        position="absolute"
                        top="5px"
                        right="5px"
                        width="32px"
                        height="32px"
                        className="settings-hover"
                        alignItems="center"
                        justifyContent="center"
                        display="none"
                        background="rgba(50, 50, 50, 0.25)"
                        backdropFilter="blur(2px)"
                        borderRadius="16px"
                        zIndex={100}
                        onClick={async (e) => {
                          e.stopPropagation()
                          const res = await api.post('/images/delete', { id: image.id })
                          asyncFunc()
                        }}
                      >
                        <Icon icon="bin-white" width={16} height={16} />
                      </Flex>
                    </Flex>
                    <ImageComponent
                      loading="lazy"
                      src={`https://clipara.b-cdn.net${image.path}?width=300`}
                      width="100%"
                      height="100%"
                      objectFit="cover"
                      borderRadius="8px"
                    />
                  </Flex>
                  <LineClamp lines={1} light fontSize="12px">
                    {image.name}
                  </LineClamp>
                </Hover>
              )
            })}
          </Grid>
        </Flex>
      ) : null}
    </ModalContainer>
  )
}

export default ImageUploadModal
