import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import Icon from '../ui/Icon.js'
import SearchCard from './SearchCard.js'
import api from '../api.js'
import ModalContainer from '../ui/ModalContainer.js'
import { H3, Text } from '../ui/Typography.js'
import { Box, Flex } from '../ui/Layout.js'
import Button from '../ui/Button.js'
import { useAuth } from '../Auth.js'
import { Input } from '../ui/Input.js'
import { colors } from '../ui/helpers.js'
import { useTheme } from '../ThemeProvider.js'
import { useContent } from '../ContentProvider.js'
import { uniq } from 'ramda'

const Grid = styled(Flex)`
  display: grid;
  grid-gap: 16px;
  row-gap: 40px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`

const InputComponent = styled.input`
  ::-webkit-input-placeholder {
    font-family: 'Inter', sans-serif;
  }
  ::-moz-placeholder {
    font-family: 'Inter', sans-serif;
  }
  :-ms-input-placeholder {
    font-family: 'Inter', sans-serif;
  }
  :-moz-placeholder {
    font-family: 'Inter', sans-serif;
  }
  background: white;
  height: 36px;
  margin: 0px;
  margin-bottom: 0px;
  border-radius: 6px;
  color: white;
  display: inline-block;
  border: none;
  font-size: 14px;
  text-decoration: none;
  color: ${colors.textDark};
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  box-shadow: 0px 1px 2px 0px rgba(50, 50, 71, 0.08), 0px 0px 1px 0px rgba(50, 50, 71, 0.2);
  border-radius: 16px;
  padding-left: 16px;
  margin-bottom: 32px;
`

const FullWidthForm = styled.form`
  width: 100%;
`

const ActiveText = styled(Text)`
  ${(props) => (props.isActive ? `text-decoration: underline;` : null)}
`

const RecipeSearchModal = ({ isOpen, setIsOpen, save }) => {
  const { user } = useAuth()
  const { recipes, refresh } = useContent()
  const [results, setResults] = useState([])
  const [isMore, setIsMore] = useState()
  const [tempSearch, setTempSearch] = useState('')
  const { theme: config } = useTheme()

  const [isInitialLoad, setIsInitialLoad] = useState(false)
  const [searchParams, setSearchParams] = useState({ organisationId: user.organisationId, page: 1, query: '', tags: [] })

  const tags = uniq(
    (recipes || [])
      .map((recipe) => recipe.tags)
      .flat()
      .filter((tag) => tag !== '')
  )
  const search = async () => {
    const res = await api.post('/search/search', {
      ...searchParams,
      fetchUnpublished: true
    })
    if (searchParams.page !== 1) {
      setResults(results.concat(res.data.payload.results))
    } else {
      setResults(res.data.payload.results)
    }

    setIsMore(res.data.pagination.isMore)
  }

  const tagOnClick = (e, tag) => {
    e.stopPropagation()
    if (searchParams.tags?.[0] === tag) {
      setSearchParams({
        ...searchParams,
        tags: []
      })
    } else {
      setSearchParams({
        ...searchParams,
        tags: [tag]
      })
    }
  }

  useEffect(() => {
    const asyncRefresh = async () => {
      await refresh()
    }
    asyncRefresh()
  }, [])

  useEffect(() => {
    console.log('RUNNING')
    search()
    setIsInitialLoad(false)
  }, [searchParams])

  return (
    <ModalContainer interiorClose p="24px" width="1000px" height="600px" isOpen={isOpen} setIsOpen={setIsOpen}>
      <Flex flexDirection="column">
        <H3 mb="16px">All Recipes</H3>
        <FullWidthForm
          onSubmit={(e) => {
            e.preventDefault()
            setSearchParams({
              ...searchParams,
              tags: [],
              query: tempSearch,
              page: 1
            })
          }}
        >
          <InputComponent placeholder="Search ..." onChange={(e) => setTempSearch(e.target.value)} value={tempSearch} />
        </FullWidthForm>
        <Flex>
          <Flex flexDirection="column" width="200px">
            {config.tagGroups && config.tagGroups.length && Math.random() > 1
              ? config.tagGroups.map((tagGroup, index) => {
                  return (
                    <Flex key={tagGroup.name} flexDirection="column" mb="28px">
                      <H3 mb="16px" fontSize="18px" lineHeight="136.523%">
                        {tagGroup.name}
                      </H3>
                      {tagGroup.tags.map((tag) => {
                        return (
                          <ActiveText
                            onClick={(e) => tagOnClick(e, tag)}
                            cursor="pointer"
                            lineHeight="136.523%"
                            isActive={tag === searchParams.tags?.[0]}
                            mb="12px"
                            key={tag}
                            fontSize="14px"
                            light
                          >
                            {tag}
                          </ActiveText>
                        )
                      })}
                      {index !== config.tagGroups.length - 1 ? (
                        <Box
                          width="calc(100% - 32px)"
                          mt="16px"
                          opacity={0.2}
                          borderBottom={`1px solid ${config.primaryFontColor}`}
                        ></Box>
                      ) : null}
                    </Flex>
                  )
                })
              : tags
                  ?.sort((a, b) => a && b && a?.toLowerCase().localeCompare(b?.toLowerCase()))
                  .map((tag) => {
                    return (
                      <ActiveText
                        onClick={(e) => tagOnClick(e, tag)}
                        cursor="pointer"
                        lineHeight="136.523%"
                        isActive={tag === searchParams.tags?.[0]}
                        mb="16px"
                        key={tag}
                        fontSize="16px"
                        light
                      >
                        {tag}
                      </ActiveText>
                    )
                  })}
          </Flex>
          <Flex flexDirection="column">
            {!results || !results.length ? (
              <Text>No results for "{searchParams.query}"</Text>
            ) : (
              <Grid>
                {results.map((result, i) => {
                  return (
                    <Box key={result.id} onClick={() => save(result)}>
                      <SearchCard result={result} />
                    </Box>
                  )
                })}
              </Grid>
            )}
            {isMore ? (
              <Flex justifyContent="center" mt="32px" mb="60px">
                <Button
                  label={'Load more'}
                  onClick={() => {
                    setSearchParams({
                      ...searchParams,
                      page: searchParams.page + 1
                    })
                  }}
                  variant="green"
                />
              </Flex>
            ) : null}
          </Flex>
        </Flex>
      </Flex>
    </ModalContainer>
  )
}

export default RecipeSearchModal
