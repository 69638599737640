import React from 'react'
import styled from 'styled-components'
import { colors } from './helpers'
import { Label, Error, Text, Span } from './Typography'
import { Flex, Box } from './Layout'
import Icon from './Icon'
import {
  space,
  width,
  color,
  flex,
  order,
  fontSize,
  alignSelf,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  borders,
  minWidth,
  minHeight,
  borderRadius,
  size,
  height,
  maxWidth,
  textAlign,
  boxShadow,
  borderColor,
  position,
  display,
  top,
  bottom,
  left,
  right,
  zIndex
} from 'styled-system'
import { props } from 'bluebird'

const InputComponent = styled.input`
  ${(props) => props.width && `width: ${props.width};`}
  border-radius: 8px;
  cursor: ${(props) => (props.cursor ? props.cursor : 'auto')};
  background: ${(props) => {
    if (props.disabled) {
      return '#FBFBFB'
    }
    return props.dark ? '#FBFBFB' : '#ffffff'
  }};
  height: ${(props) => (props.large ? '40px' : '36px')};
  margin: 0px;
  margin-bottom: 0px;
  ${(props) => !props.noBorder && 'box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);'}
  border-radius: 6px;
  color: white;
  display: inline-block;
  border: 1.5px solid transparent;

  ${(props) => (props.border ? `border: ${props.border};` : '')}
  font-size: 14px;
  padding-left: 24px;
  padding-right: 24px;
  text-decoration: none;
  color: ${colors.textDark};
  ${(props) => props.disabled && 'cursor: not-allowed;'}
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  ${position};
  ${width};
  ${space};
  ${fontSize};
  ${color};
  ${flex};
  ${order};
  ${alignSelf};
  ${borders};
  ${borderColor};
  ${minWidth};
  ${minHeight};
  ${borderRadius};
  ${size};
  ${height};
  ${textAlign};
  ${maxWidth};
  ${boxShadow};
  ${display};
  ${top};
  ${bottom};
  ${left};
  ${right};
  ${zIndex};
  ::-webkit-calendar-picker-indicator {
    background: none;
  }
  ::-webkit-input-placeholder {
    color: #b5b5b5;
    font-family: 'Inter', sans-serif;
  }
  ::-moz-placeholder {
    color: #b5b5b5;
    font-family: 'Inter', sans-serif;
  }
  :-ms-input-placeholder {
    color: #b5b5b5;
    font-family: 'Inter', sans-serif;
  }
  :-moz-placeholder {
    color: #b5b5b5;
    font-family: 'Inter', sans-serif;
  }
  ${(props) => (props.error ? `border: 1.5px solid ${colors.error};` : '')}
  ${(props) =>
    !props.noBorder &&
    `:focus {
    border: 1.5px solid #7895ff;
    box-shadow: 0px 0px 0px 2px rgba(76, 111, 255, 0.3);
  }`}
`

const MaxLength = styled(Text)`
  font-size: 12px;
  position: absolute;
  right: 8px;
  bottom: 0px;
  color: ${colors.textLight};
`

const TextPostFixComponent = styled(Text)`
  font-size: ${(props) => (props.largeTextPostFix ? '16px' : '12px')};
  position: absolute;
  right: ${(props) => (props.largeTextPostFix ? '24px' : '8px')};
  bottom: 8px;
  color: ${colors.textLight};
`

const Tooltip = styled(Icon)`
  cursor: pointer;
`

const NoWrapLabel = styled(Label)`
  white-space: nowrap;
`

export const Input = ({
  label,
  error,
  mb,
  mr,
  ml,
  mt,
  width,
  textPostFix,
  largeTextPostFix,
  tooltip,
  subLabel,
  optional,
  ...rest
}) => {
  return (
    <Flex borderRadius="4px" position="relative" width={width} flexDirection="column" mb={mb} mr={mr} mt={mt} ml={ml}>
      {label && (
        <>
          <NoWrapLabel display="flex" data-tip={tooltip} alignItems="center">
            {label}{' '}
            {tooltip && (
              <Box ml="8px" data-tip={tooltip}>
                <Tooltip width="16" height="16" icon="tooltip-grey" />
              </Box>
            )}
            {subLabel && (
              <Span ml="8px" mt="1px" fontSize="12px">
                {subLabel}
              </Span>
            )}
          </NoWrapLabel>
        </>
      )}
      <InputComponent width={width} error={error} {...rest} />
      {rest.maxLength && rest.value && !rest.dontShowMax ? <MaxLength>{rest.maxLength - rest.value.length}</MaxLength> : null}
      {textPostFix ? <TextPostFixComponent largeTextPostFix={largeTextPostFix}>{textPostFix}</TextPostFixComponent> : null}
      {error && (
        <Error mt="8px" mb="-22px">
          {error}
        </Error>
      )}
    </Flex>
  )
}

export const SideInput = (props) => {
  return (
    <Flex mb="16px" px="24px" alignItems="center" justifyContent="space-between">
      <Flex width="auto">
        <Label mb="0">{props.label}</Label>
        {props.tooltip && (
          <Box ml="8px" data-tip={props.tooltip}>
            <Tooltip width="16" height="16" icon="tooltip-grey" />
          </Box>
        )}
      </Flex>
      <Input {...props} width="150px" label={undefined} />
    </Flex>
  )
}

export const TextArea = styled.textarea`
  border-radius: 8px;
  background: #fbfbfb;
  ${(props) => !props.noBorder && 'box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);'}
  display: inline-block;
  padding: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  border: none;
  text-decoration: none;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  resize: ${(props) => props.resize || 'none'};
  font-family: 'Inter', sans-serif;
  text-decoration: none;
  color: ${colors.textDark};
  font-size: 14px;
  ${position};
  ${width};
  ${space};
  ${fontSize};
  ${color};
  ${flex};
  ${order};
  ${alignSelf};
  ${borders};
  ${borderColor};
  ${minWidth};
  ${minHeight};
  ${borderRadius};
  ${size};
  ${height};
  ${textAlign};
  ${maxWidth};
  ${boxShadow};
  ${display};
  ${top};
  ${bottom};
  ${left};
  ${right};
  ${zIndex};
`
