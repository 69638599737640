import { useState } from 'react'
import Button from '../ui/Button'
import Icon from '../ui/Icon'
import Image from '../ui/Image'
import ImageUploadModal from '../ui/ImageUploadModal'
import { Input, TextArea } from '../ui/Input'
import { Box, Flex } from '../ui/Layout'
import { Error, Text } from '../ui/Typography'
import { uniq, update } from 'ramda'
import AddStepsModal from './AddStepsModal'
import styled from 'styled-components'
import { ReactSortable } from 'react-sortablejs'
import { v4 as uuidv4 } from 'uuid'
const FormWithMargin = styled.form`
  width: 100%;
  ${({ isFirst }) => isFirst && 'margin-top: 0px;'}
`

const SectionRow = ({ i, setRecipeSteps, recipeSteps, step }) => {
  return (
    <FormWithMargin isFirst={i === 0}>
      <Flex justifyContent="space-between" alignItems="center" mb="16px">
        <Flex alignItems="center" width="100%">
          <GrabIcon mr="8px" icon="drag-circles-grey" width="24px" height="24px" alt="drag icon" />
          <Box width="100%">
            <Input
              width="100%"
              placeholder="eg. For the sauce"
              border="1px solid #CBD5E0"
              onChange={(e) => {
                const value = e.target.value
                const newSteps = update(i, { ...step, section: value, isSection: true }, recipeSteps)
                setRecipeSteps(newSteps)
              }}
              value={step.section}
            />
          </Box>
          <Icon
            cursor="pointer"
            ml="8px"
            icon="circle-cross-grey-outline"
            width="16px"
            height="16px"
            onClick={() => {
              setRecipeSteps(recipeSteps.filter((el, ii) => ii !== i))
            }}
          />
        </Flex>
      </Flex>
    </FormWithMargin>
  )
}

const RecipeSteps = ({ recipeSteps, setRecipeSteps, errors, values }) => {
  const [showAddStepsModal, setShowAddStepsModal] = useState(false)

  const addStep = () => {
    setRecipeSteps([
      ...recipeSteps,
      {
        id: uuidv4(),
        time: 0,
        formattedTime: '00:00'
      }
    ])
  }

  const addSection = () => {
    setRecipeSteps([
      ...recipeSteps,
      {
        id: uuidv4(),
        section: '',
        isSection: true
      }
    ])
  }

  return (
    <>
      <Text mb="8px" color="black" fontSize="18px" fontWeight="600" width="100%">
        Recipe Steps
      </Text>
      <Text mb="24px" light fontSize="14px" fontWeight="400" width="100%">
        Step by step instructions for the recipe
      </Text>
      <ReactSortable
        list={recipeSteps}
        setList={(newSteps) => {
          setRecipeSteps(newSteps)
        }}
      >
        {recipeSteps.map((step, i) => {
          if (step.isSection) {
            return (
              <SectionRow
                key={step.id || `section-${Date.now()}`}
                i={i}
                step={step}
                recipeSteps={recipeSteps}
                setRecipeSteps={setRecipeSteps}
              />
            )
          }
          return (
            <RecipeStep
              errors={errors}
              key={step.id}
              i={i}
              step={step}
              recipeSteps={recipeSteps}
              setRecipeSteps={setRecipeSteps}
              values={values}
            />
          )
        })}
      </ReactSortable>

      {recipeSteps.length === 0 ? (
        <Flex flexDirection="column" alignItems="center" mt="48px">
          <Text light textAlign="center" mb="8px" lineHeight="23px">
            Enter steps in bulk and we'll do the rest
          </Text>
          <Box mb="48px">
            <Button label="Quick add" variant="green" onClick={() => setShowAddStepsModal(true)} />
          </Box>
          <Flex justifyContent="center" pb="40px" borderBottom="1px solid rgba(237, 242, 247, 1)" mb="16px">
            <Text light mr="32px" cursor="pointer" fontWeight="500" onClick={addStep}>
              + Add step
            </Text>
            <Text light cursor="pointer" fontWeight="500" onClick={addSection}>
              + Add section heading
            </Text>
          </Flex>
        </Flex>
      ) : (
        <Flex>
          <Text mr="32px" light cursor="pointer" fontWeight="500" onClick={addStep}>
            + Add step
          </Text>
          <Text mr="32px" light cursor="pointer" fontWeight="500" onClick={addSection}>
            + Add section heading
          </Text>
        </Flex>
      )}

      {errors[`steps`] && (
        <Error mt="4px" zIndex={1}>
          {errors[`steps`]}
        </Error>
      )}
      <AddStepsModal isOpen={showAddStepsModal} setIsOpen={setShowAddStepsModal} setSteps={setRecipeSteps} steps={recipeSteps} />
    </>
  )
}

const GrabIcon = styled(Icon)`
  cursor: grab;
`

const RecipeStep = ({ recipeSteps, setRecipeSteps, step, i, errors, values }) => {
  const [imageUploadOpen, setImageUploadOpen] = useState(false)
  return (
    <Flex flexDirection="column">
      <Flex mb="32px" alignItems="center">
        <GrabIcon mr="8px" icon="drag-circles-grey" width="24px" height="24px" alt="drag icon" />
        <Flex
          backgroundColor="#fbfbfb"
          boxShadow="0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);"
          borderRadius="8px"
        >
          <Flex flexDirection="column">
            <TextArea
              noBorder
              onChange={(e) => {
                const value = e.target.value
                const newSteps = update(i, { ...step, text: value }, recipeSteps)
                setRecipeSteps(newSteps)
              }}
              value={step.text}
              placeholder="Enter details"
              name={`step-${i}`}
              width="100%"
              mr="16px"
              mb="8px"
              height="100%"
            />
            {values.hasStepPlayer && values.videoId ? (
              <Flex>
                <Input
                  noBorder
                  height="24px"
                  mb="8px"
                  dark
                  type="time"
                  onChange={(e) => {
                    const value = e.target.value
                    let seconds
                    try {
                      seconds = Number(value.split(':')[0]) * 60 + Number(value.split(':')[1])
                    } catch (e) {}
                    const newSteps = update(i, { ...step, formattedTime: value, time: seconds }, recipeSteps)
                    setRecipeSteps(newSteps)
                  }}
                  value={step.formattedTime}
                  pl="24px !important"
                  pr="8px !important"
                  placeholder="mm:ss"
                />
              </Flex>
            ) : null}
            {errors[`step-${i}`] && (
              <Error mt="-10px" px="24px" zIndex={1}>
                {errors[`step-${i}`]}
              </Error>
            )}
          </Flex>
          {step.image ? (
            <Box m="8px" width="118px" minWidth="118px" height="118px" onClick={() => setImageUploadOpen(true)} cursor="pointer">
              <Image
                src={step.image + '?width=400&height=400'}
                width="118px"
                height="118px"
                objectFit="cover"
                borderRadius="8px"
              />
            </Box>
          ) : (
            <Flex
              m="8px"
              borderRadius="8px"
              width="118px"
              minWidth="118px"
              height="118px"
              cursor="pointer"
              justifyContent="center"
              alignItems="center"
              border="1px dashed #718096"
              onClick={() => setImageUploadOpen(true)}
            >
              <Icon icon="image-dark-grey" width="20px" height="20px" />
            </Flex>
          )}
          <ImageUploadModal
            isOpen={imageUploadOpen}
            setIsOpen={setImageUploadOpen}
            saveFunction={(urls) => {
              const newSteps = update(i, { ...step, image: urls[0] }, recipeSteps)
              setRecipeSteps(newSteps)
            }}
          />
        </Flex>
        <Flex height="126px" width="auto" alignItems="center" ml="12px">
          <Icon
            cursor="pointer"
            icon="circle-cross-grey-outline"
            width="16"
            minWidth="16"
            height="16"
            onClick={() => {
              setRecipeSteps(recipeSteps.filter((el, ii) => ii !== i))
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default RecipeSteps
