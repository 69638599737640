import React, { useState, useRef, useEffect } from 'react'
import hexToRgba from 'hex-to-rgba'
import styled from 'styled-components'
import { Box, Flex } from '../../ui/Layout'
import api from '../../api'
import Image from '../../ui/Image'
import { Text } from '../../ui/Typography'
import Button from '../../ui/Button'
import { useInbox } from '../../InboxProvider'
import { useAuth } from '../../Auth'

const TextArea = styled.textarea`
  padding: 12px;
  height: 100%;
  width: 100%;
  border: none;
  text-decoration: none;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  resize: none;
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #425466;
`

const ImageContainer = styled(Flex)`
  @media (max-width: 700px) {
    display: none;
  }
`

const MobileImageContainer = styled(Box)`
  width: 24px;
  min-width: 24px;
  display: none;
  @media (max-width: 700px) {
    display: block;
  }
`

const StyledInput = styled.input`
  display: none;
`

const ImageButton = styled(Flex)`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(68, 68, 68, 0.5);
`

const EditComment = ({ commentId, initialComment, initialImageUrl, config, onCancel }) => {
  const { refresh } = useInbox()
  const { refresh: refreshAuth } = useAuth()

  const [comment, setComment] = useState(initialComment)
  const [isSending, setIsSending] = useState(false)
  const [files, setFiles] = useState([])
  const [imageUrl, setImageUrl] = useState(initialImageUrl)
  const [id] = useState(Math.round(Math.random() * 100000))

  const textareaRef = useRef(null)

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus()
    }
  }, [])

  const handleUpdate = async () => {
    if (!comment) return

    setIsSending(true)
    try {
      await api.post('/comments/update', {
        commentId,
        comment,
        imageUrl
      })

      await api.post('/organisations/update-inbox-last-read')
      await refreshAuth()
      await refresh()
      onCancel()
    } catch (error) {
      console.error('Error updating comment:', error)
    }
    setIsSending(false)
  }

  const uploadImage = async () => {
    const filePicker = document.getElementById(`clipara-file-picker-edit-${id}`)
    filePicker.click()
  }

  useEffect(() => {
    const uploadFile = async () => {
      if (files && files[0]) {
        const file = files[0]
        let formData = new FormData()
        formData.append('file', file)
        formData.append('name', file.name)
        formData.append('path', 'clubb-comment-images')
        const res = await api.post('/comments/image-upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        setImageUrl(res.data.payload.url)
      }
    }
    uploadFile()
  }, [files])

  return (
    <Flex flexDirection="column">
      <Flex
        position="relative"
        height="116px"
        borderRadius="16px"
        border={`1px solid ${hexToRgba(config.primaryFontColor, 0.3)}`}
        bg="white"
        p="8px"
      >
        <TextArea ref={textareaRef} value={comment} onChange={(e) => setComment(e.target.value)} />
        <ImageContainer
          width="100px"
          minWidth="100px"
          height="100px"
          border={imageUrl ? 'none' : '1px dashed #718096'}
          borderRadius="8px"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          onClick={uploadImage}
          overflow="hidden"
          position="relative"
        >
          {imageUrl ? (
            <Image width="100px" height="100px" objectFit="cover" src={imageUrl + '?width=300&height=300'} />
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.0015 2H2.00146C1.44918 2 1.00146 2.44772 1.00146 3V12L3.64791 9.64646C3.81644 9.47793 4.0805 9.45178 4.27882 9.58399L6.93776 11.3566L10.6479 7.64646C10.8001 7.49428 11.0326 7.45655 11.2251 7.5528L15.0015 9.50001V3C15.0015 2.44772 14.5537 2 14.0015 2ZM2.00146 1C0.896895 1 0.00146484 1.89543 0.00146484 3V13C0.00146484 14.1046 0.896895 15 2.00146 15H14.0015C15.106 15 16.0015 14.1046 16.0015 13V3C16.0015 1.89543 15.106 1 14.0015 1H2.00146ZM6.00146 5.5C6.00146 6.32843 5.32989 7 4.50146 7C3.67304 7 3.00146 6.32843 3.00146 5.5C3.00146 4.67157 3.67304 4 4.50146 4C5.32989 4 6.00146 4.67157 6.00146 5.5Z"
                fill="#718096"
              />
            </svg>
          )}
          {imageUrl && (
            <ImageButton
              position="absolute"
              bottom="2px"
              right="2px"
              onClick={(e) => {
                e.stopPropagation()
                setImageUrl(null)
              }}
            >
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.30769 5.86667C5.60508 5.86667 5.84615 6.10545 5.84615 6.4V12.8C5.84615 13.0946 5.60508 13.3333 5.30769 13.3333C5.01031 13.3333 4.76923 13.0946 4.76923 12.8V6.4C4.76923 6.10545 5.01031 5.86667 5.30769 5.86667Z"
                  fill="white"
                />
                <path
                  d="M8 5.86667C8.29738 5.86667 8.53846 6.10545 8.53846 6.4V12.8C8.53846 13.0946 8.29738 13.3333 8 13.3333C7.70262 13.3333 7.46154 13.0946 7.46154 12.8V6.4C7.46154 6.10545 7.70262 5.86667 8 5.86667Z"
                  fill="white"
                />
                <path
                  d="M11.2308 6.4C11.2308 6.10545 10.9897 5.86667 10.6923 5.86667C10.3949 5.86667 10.1538 6.10545 10.1538 6.4V12.8C10.1538 13.0946 10.3949 13.3333 10.6923 13.3333C10.9897 13.3333 11.2308 13.0946 11.2308 12.8V6.4Z"
                  fill="white"
                />
              </svg>
            </ImageButton>
          )}
        </ImageContainer>

        <StyledInput
          type="file"
          id={`clipara-file-picker-edit-${id}`}
          name="file-picker"
          accept={'image/jpg, image/png, image/jpeg'}
          multiple={false}
          onChange={(e) => {
            if (e.target.files[0]) {
              setFiles(Array.from(e.target.files))
            }
          }}
        />
      </Flex>

      <Flex alignItems="center" justifyContent="flex-end" my="16px">
        <Text onClick={onCancel} cursor="pointer" light fontSize="14px" fontWeight="600" mr="40px">
          Cancel
        </Text>
        <Button
          backgroundColor={config.primaryButtonColor}
          fontSize="14px"
          color={config.primaryButtonFontColor}
          isDisabled={!comment || isSending}
          onClick={handleUpdate}
          isLoading={isSending}
          label="Save"
        />
      </Flex>
    </Flex>
  )
}

export default EditComment
